/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { useState } from "react";
import HeaderLinks from "./HeaderLinks";
import KontaktInfo from "./KontaktInfo"

import ButtonColorMode from "./ButtonColorMode"

const styleToggleOpenOnMobile = {
  display: ["flex", "flex", "flex", "none"],
  cursor: "pointer",
  padding: "0 10px",
  height: "inherit",
  border: 'unset',
  textAlign: 'unset',
  backgroundColor: 'unset'
};

const styleHamburgerButton = ({ headerbaropen }) => {
  return {
    backgroundColor: "highlight",
    width: "30px",
    height: 1,
    alignSelf: "center",
    position: "relative",
    transition: "all 0.3s linear",
    transform: headerbaropen ? "rotate(-45deg)" : "inherit",

    "::before": {
      width: "30px",
      height: 1,
      backgroundColor: "highlight",
      content: '""',
      position: "absolute",
      transition: "all 0.3s linear",
      transform: headerbaropen ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)",
      top: "-10px",
    },

    "::after": {
      width: "30px",
      height: 1,
      backgroundColor: "highlight",
      content: '""',
      position: "absolute",
      transition: "all 0.3s linear",
      opacity: `${headerbaropen ? "0" : "1"}`,
      transform: `${headerbaropen ? "rotate(90deg) " : "rotate(0deg)"}`,
      top: "10px",
    },
  }
};

const styleNav = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  px: [0, 0, 0, 0, "5%"],
};

const styleKontaktInfo = {
  px: "4px",
  borderStyle: "groove",
  borderWidth: "thin",
  borderRadius: "5px",
  display: ["block", "block", "block", "none"]
}

const styleLinksBox = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexDirection: ["column", "column", "column", "row"],
  backgroundColor: "headerback",
  top: "10vh",
  width: "100%",
  position: ["fixed", "fixed", "fixed", "static"],
  transition: "all 0.5s ease-in-out",

  '& #mobileDrawer': {
    transitionDelay: "0.5s",
  },
}


const HeaderBar = ({ heightHeaderBar, indexBreakpoint }) => {

  const isLinksHorizontal = indexBreakpoint > 2
  const [headerbaropen, setHeaderbarOpen] = useState(false);

  const handleDrawerToggleClick = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    event.stopPropagation();
    event.bubbles = false
    setHeaderbarOpen((o) => !o);
  };

  return (
    <div sx={{
      position: "sticky",
      display: 'flex',
      zIndex: 1,
      top: 0,
      backgroundColor: "headerback",
      px: [0, "10px"],
    }}>
      <ButtonColorMode myStyle={{ marginRight: "auto", alignSelf: "center" }} />
      <nav sx={{ ...styleNav, height: heightHeaderBar, }}>
        <Link
          activeClassName="active"
          sx={{ variant: "links.styledHeaderLink", alignSelf: "center" }}
          to="/"
          onClick={() => setHeaderbarOpen(false)}
        >
          pc123
        </Link>
        <KontaktInfo bgColor="accent" color="heading" sxStyle={styleKontaktInfo} />

        <div style={{ mx: "auto" }} />
        <button
          sx={styleToggleOpenOnMobile}
          onClick={handleDrawerToggleClick}
        >
          <div sx={styleHamburgerButton({ headerbaropen })} />
        </button>
        <div id="links" sx={{
          ...styleLinksBox,
          transform: isLinksHorizontal ? 'translateX(0px)' : headerbaropen ? 'translateX(0px)' : 'translateX(-100%)',
          opacity: isLinksHorizontal ? 1 : headerbaropen ? 1 : 0,
          '& #mobileDrawer': {
            borderRadius: isLinksHorizontal ? 'unset' : headerbaropen ? '20px' : '0px',
            transform: isLinksHorizontal ? 'unset' : headerbaropen ? 'skewX(0deg)' : 'skewX(10deg)'
          },
        }}>
          <HeaderLinks />
        </div>
      </nav>
    </div>
  );
};

export default HeaderBar;
