/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
import ColorModeToggle from "./ColorModeToggle"

const ButtonColorMode = ({ myStyle }) => {

  const [colorMode, setColorMode] = useColorMode();

  const isDark = colorMode === `dark`

  const toggleColorMode = (e) => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
  );
};

export default ButtonColorMode;
