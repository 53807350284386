/** @jsx jsx */
import { jsx } from "theme-ui";

import Footer from "../components/shared/_footer";
import HeaderImage from "../components/shared/HeaderImage";
import HeaderBar from "../components/shared/HeaderBar";


const heightHeaderBar = "5rem"

const NotFound = (props) => {
  const { location } = props

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      overflowX: "hidden",
    }}>
      <HeaderBar heightHeaderBar={heightHeaderBar} />
      <HeaderImage pageHeader={{ tag: "div", value: "Stránka sa nenašla" }} positionTop={heightHeaderBar} />

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flex: 1,
          pt: 0,
        }}
      >
        <p style={{ marginTop: "50px" }}>Ah, nenašli...</p>
        <p >
          {`Taká stránka ${location.href} neexistuje...`}
        </p>
      </div>
      <Footer />
    </div>

  );
};

export default NotFound;
