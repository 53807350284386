/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";


const styleDropdown = {
  display: "inline-block",
  pl: "5px",
  fontSize: "initial",
  overflow: "visible",
  verticalAlign: "-0.125em",
  "& svg": {
    height: "1em",
    width: "0.875em",
  },
}

const submenuStyle = {
  "&.submenu1": { left: ["80px", '80px', '80px', "20px"], }
}

const styleLinksBox = {

  display: "flex",
  justifyContent: "flex-end",
  alignItems: 'flex-start',
  flexDirection: ["column", "column", "column", "row"],
  backgroundColor: "headerback",
  top: 0,
  position: ["fixed", "fixed", "fixed", "static"],
  width: ["100%", "100%", "100%", "auto"],
  maxWidth: '350px',
}


const HeaderLinks = () => {

  return (
    <div id="mobileDrawer"
      sx={styleLinksBox}
    >
      <Link
        activeClassName="active"
        sx={{ variant: "links.styledHeaderLink" }}
        to="/pocitacove-siete/"
      >
        Počítačové siete
      </Link>
      <div
        sx={{ variant: "links.styledHeaderDropdownLink", tabIndex: 0 }}
        aria-haspopup={true}
      >
        <div>Kamery
          <div sx={styleDropdown} >
            <svg aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              role="img"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
            </svg>
          </div>
        </div>
        <div sx={{ position: "relative" }}>
          <div aria-label="submenu"
            sx={submenuStyle}
            className="submenu1"
          >
            <Link
              activeClassName="active"
              sx={{ variant: "links.styledHeaderLink", display: "block" }}
              to="/kamerove-systemy/"
            >
              Kamerové systémy
            </Link>
            <Link
              activeClassName="active"
              sx={{ variant: "links.styledHeaderLink", display: "block" }}
              to="/referencie/"
            >
              Z našich prác
            </Link>
          </div>
        </div>
      </div>

      <Link
        activeClassName="active"
        sx={{ variant: "links.styledHeaderLink" }}
        to="/referencie/"
      >
        Z našich prác
      </Link>
      <Link
        activeClassName="active"
        sx={{ variant: "links.styledHeaderLink" }}
        to="/alarmy/"
      >
        Alarmy
      </Link>
      <div
        sx={{ variant: "links.styledHeaderDropdownLink", tabIndex: 0 }}
        aria-haspopup={true}
      >
        <div>Riadenie prístupu
          <div sx={styleDropdown} >
            <svg aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              role="img"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
            </svg>
          </div>
        </div>
        <div sx={{ position: "relative" }}>
          <div aria-label="submenu"
            sx={submenuStyle}
            className="submenu1"
          >
            <Link
              activeClassName="active"
              sx={{ variant: "links.styledHeaderLink", display: "block" }}
              to="/videovratniky/"
            >
              Videovrátniky
            </Link>
            <Link
              activeClassName="active"
              sx={{ variant: "links.styledHeaderLink", display: "block" }}
              to="/terminaly/"
            >
              Prístupové terminály
            </Link>
          </div>
        </div>
      </div>
      <Link
        activeClassName="active"
        sx={{ variant: "links.styledHeaderLink" }}
        to="/problem-kamerove-systemy/"
      >
        Pomoc pre Vás
      </Link>
      <Link
        activeClassName="active"
        sx={{ variant: "links.styledHeaderLink" }}
        to="/kontakt/"
      >
        Kontakt
      </Link>
    </div>
  );
};

export default HeaderLinks;
