/** @jsx jsx */
import { Link } from "gatsby";
import { Box, jsx, Grid } from "theme-ui";

const defaultStyle = {
  p: [1, 2, 3],
  my: [1, 2, 3],
  mx: 0,
  backgroundColor: "footerback",
  color: "headertext",
  a: {
    color: "headerlink",
    "&:hover": {
      color: "headerlinkhover",
    },
  },
};


const Footer = ({ myStyle }) => {
  return (
    <Grid
      as="footer"
      gap={2}
      columns={"2fr 10fr"}
      sx={{ ...defaultStyle, ...myStyle }}
    >
      <div>
        <div>© {new Date().getFullYear()}</div>
      </div>
      <Box sx={{ textAlign: "right" }}>
        <Link to="/kamerove-systemy/" activeClassName="active">
          https://pc123.sk/kamerove-systemy/
        </Link>
        <div>
          <span role="img" aria-label="email">
            📧
          </span>
          &nbsp;
          <a href="mailto:info@pc123.sk">info@pc123.sk</a>
          &nbsp;|&nbsp;☎️&nbsp;tel.: +421 940 883 633
        </div>
        <div>pc 123, s.r.o. | Skalica | IČO: 52604586 DIČ: 2121096725</div>
        <div>
          Zap. v OR: okr. súd Trnava, oddiel Sro - Vložka číslo:  45332/T
        </div>
      </Box>
    </Grid>
  );
};

export default Footer;
